/*******************************************/

/*BEGIN - clases banner */

.body-estruct .banner {
  content: ' ';
  display: block;
  left: 0;
  top: 0;
  width: 100%;
  min-height: 700px;
  z-index: 1;
  padding: 20px 0px 0px;
  background-color: gray;
  background-position: 0px 40px;
}

.body-estruct .banner::before {
  opacity: 0.5;
}

.banner-bg-height {
  min-height: 700px;
}

.body-estruct {
  min-height: calc(100vh - 630px);
}

.img-back{
  z-index: 1;
  position: absolute;
}

.paddingLimit {
  padding: 20px 5px !important;
}

.icoInfo{ 
  color: #2F80ED;
  cursor: pointer;
  margin-top: 45px; 
  margin-right: 10px;  
  font-size: 20px;
   }

.icoInfo:hover~.dialogo{
  display: block;
}

.tool-tip {
  color: #000;
  background-color: #283773;
  font-family: Montserrat;
  font-size: 12px !important;
}

/*******************************************/

/*BEGIN - card autos */

.card-auto {
  width: 90vw;
  min-width: 300px;
  max-width: 460px;
  min-height: 400px;
  background-color: #fff;
  padding: 20px 30px;
  margin: auto;
  border-radius: 10px;
}

.card-auto:not([class*='mat-elevation-z']) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.card-auto .table-sio .row-sio .cell-sio {
  padding-top: 5px;
}

.body-estruct .banner h1 {
  line-height: 2.5rem;
}

.body-estruct .banner h1 span.recuadro {
  background-color: #0e1751;
  padding-left: 15px;
  padding-right: 15px;
}

.body-estruct .banner h3 span {
  background-color: #0e1751;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.body-estruct .banner h3.title {
  color: white;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.body-estruct .banner p.description {
  color: white;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.title-pages{
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 32px;
  text-transform: uppercase;
  color: #FFFFFF;
}


/*******************************************/

@media (min-width: 320px) {
  /*ssm*/
  .body-estruct .banner h1 {
    font-size: 1.5em;
    line-height: 2rem;
  }
  h3.destacado {
    font-size: 0.75rem;
    line-height: 0.75rem;
    padding: 0px 5px;
    margin: 0px;
  }
}

.d-none{
  display: none;
}

.d-block {
  display: block;
}

@media (min-width: 576px) {
  /*sm*/
}

@media (min-width: 768px) {
  /*md*/
}

@media (min-width: 992px) {
  /*lg*/
  .body-estruct .banner h1 {
    font-size: 2em;
    line-height: 2.5rem;
  }
}

@media (min-width: 1200px) {
  /*xl*/
}

@media (min-width: 1280px) {
  /*xl*/
}

.circle-right {
  width: 80px;
  height: 80px;
}

.accordion.top {
  border-top: solid 1px #d4cfcf;
}

.accordion {
  background-color: transparent;
  font-weight: bold;
  color: #000000;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  transition: 0.4s;
  border-bottom: solid 1px #d4cfcf;
}

/* .active, .accordion:hover {
    background-color: #ccc; 
} */

.panel {
  padding: 0 18px;
  display: none;
  background-color: transparent;
  overflow: hidden;
  border-bottom: solid 1px #d4cfcf;
}

.proteccion {
  min-height: 756px;
  background-color: #0F1852;
  padding-top: 20px;
  padding-bottom: 20px;
}

/*BEGIN - slider */

@media (max-width: 1200px) {
  /*xl*/
  @keyframes scroll {
    0% {
      transform: translateX(calc(220px * 6.6));
    }
    100% {
      transform: translateX(calc(-220px * 6.6));
    }
  }
}

@media (min-width: 1201px) {
  /*xl*/
  .slider .slide-track {
    margin: auto;
  }
}

.slider {
  background: white;
  box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.125);
  height: 70px;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.slider::before, .slider::after {
  /* background: linear-gradient(to right, white 0%, rgba(255, 255, 255, 0) 100%); */
  content: "";
  height: 70px;
  position: absolute;
  width: 100px;
  z-index: 2;
}

.slider::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}

.slider::before {
  left: 0;
  top: 0;
}

.slider .slide-track {
  animation: scroll 25s linear infinite;
  display: flex;
  width: calc(220px * 7);
}

.slider .slide {
  height: 70px;
  width: 400px;
  display: inline-grid;
}

.slider .slide-b {
  height: 70px;
  width: 100%;
  display: inline-grid;
}

/* Slider Fin */

/*Slider cheko*/
.slider-checo {
  box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.125);
  height: auto;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.slider-checo::before, .slider::after {
  /* background: linear-gradient(to right, white 0%, rgba(255, 255, 255, 0) 100%); */
  content: "";
  height: auto;
  position: absolute;
  width: 100px;
  z-index: 2;
}

.slider-checo::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}

.slider-checo::before {
  left: 0;
  top: 0;
}

.slider-checo .slide-track {
  animation: scroll 25s linear infinite;
  display: flex;
  width: calc(220px * 7);
}

.slider-checo .slide {
  height: auto;
  width: 400px;
  display: inline-grid;
}

.slider-checo .slide-b {
  height: auto;
  width: 100%;
  display: inline-grid;
}

/*FIN SLIDER CHECO*/

.ciaChubb {
  height: 45px;
  max-width: 130px;
  vertical-align: middle;
  align-items: center;
  align-self: center;
  margin: auto;
}

.size-100 {
  width: 100%;
}

.ciaQualitas {
  height: 50px;
  max-width: 191px;
  vertical-align: middle;
  align-items: center;
  align-self: center;
  margin: auto;
}


.ciaAfirme {
  height: 50px;
  max-width: 168px;
  vertical-align: middle;
  align-items: center;
  align-self: center;
  margin: auto;
}

.ciaAxa {
  height: 60px;
  max-width: 300px;
  vertical-align: middle;
  align-items: center;
  align-self: center;
  margin: auto;
}

.ciaHdi {
  height: 65px;
  max-width: 100px;
  vertical-align: middle;
  align-items: center;
  align-self: center;
  margin: auto;
}

.ciaPrimeroSeguros {
  height: 65px;
  max-width: 200px;
  vertical-align: middle;
  align-items: center;
  align-self: center;
  margin: auto;
}

.ciaMapfre {
  height: 40px;
  max-width: 250px;
  vertical-align: middle;
  align-items: center;
  align-self: center;
  margin: auto;
}

.ciaGNP {
  height: 60px;
  max-width: 250px;
  vertical-align: middle;
  align-items: center;
  align-self: center;
  margin: auto;
}

.banner-bf {
  height: 200px;
  width:100%;
  max-width: 850px;
  vertical-align: middle;
  align-items: center;
  align-self: center;
  margin: auto;
}

.logoCoche {
  height: 110px;
}

.logoCocheTitle{
  height: 70px!important;
  width: 200px!important;
}

.logoCirculares {
  width: 50px;
  height: 50px;
}

.bg-blue-inter {
  background-color: #0e1751;
}

.imgCoche {
  width: 180px;
  height: auto;
}

.imgSeguroSSL {
  margin-top: 15px;
  width: 100px;
  height: auto;
}

.container.coche h1 {
  line-height: 2.3rem;
  font-size: 1.8rem;
  margin-block-end: 0em;
  font-weight: 900;
}

.container.coche p.description {
  font-size: 1.25rem;
  line-height: 1.7rem;
  margin-block-start: 0em;
  margin-block-end: 0em;
}

.container.coche .link {
  font-size: 1.25rem;
  line-height: 1.7rem;
  color: white;
  cursor: pointer;
  /* border-bottom: 1px solid white; */
}

.pink-coche {
  color: #DC0080;
  background-color: #00b0fe;
}

.green-coche {
  color: #CEEF0C;
  background-color: #00b0fe;
}

.blue-coche {
  color: #00FAFE;
  background-color: #00b0fe;
}

.otros-seguros {
  margin-top: 50px;
  margin-bottom: 50px;
}

.otros-seguros h1 {
  line-height: 2.3rem;
  margin-block-end: 0em;
}

.otros-seguros h2 {
  line-height: 1.8rem;
  margin-block-start: 0em;
  margin-block-end: 0em;
}

.cubo-seguros {
  width: 220px;
  height: 200px;
  border: 1px solid #77CAE3;
  border-radius: 16px;
  text-align: center;
  padding: 10px;
  cursor: pointer;
  margin: 10px auto;
  background: #FFFFFF;
}

.cubo-seguros:hover {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.cubo-seguros .table-sio {
  width: 210px;
  height: 210px;
}

.imgLogo {
  width: 100px;
}

.imgBienSeguro {
  width: 172px;
  height: 100px;
  margin-top : 25px;
}

.cubo-seguros p {
  font-size: 1.5rem;
  color: #999999;
}

fieldset {
  border: 0 none;
}

.lowercase {
  text-transform: lowercase;
}

.mejor-servicio {
  font-weight: unset;
}

@media screen and (min-width: 600px) {
  .ocultar-div{
    display:none;
  }
}

@media screen and (max-width: 600px) {
  .ocultar-btn{
    display:none;
  }
}

.img-check {
  color: gray;
  background-color: #fff;
  border: 1px solid #ced4da;
  cursor: pointer;
  border-radius: 0.75rem;
  justify-content: center;
  display: flex;
}

.pr-015rem {
  padding-right: 0.15rem;
}

.pr-115rem {
  padding-right: 1.15rem;
}

.align-middle {
  display: flex;
  justify-content: center;
  align-content: center;
  padding-bottom: 0.75rem;
}

.align-between {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding-bottom: 0.75rem;
}

.txEdad{
  max-width: 127px;
}

.img-stl {
  margin: -8px 0;
}

.mh-212x{
  max-height: 212px;
}

.mouse-pointer {
  cursor: pointer;
}

.abs-p {
  position: absolute;
}

.img-popup {
  width: 2rem;
}

.mt-popup{
  margin-top: -20px;
}

.input-custom-cp-error {
  font-size: 15px;
  color: red; 
}

.input-custom-error {
  color: red;
}



@media (min-width: 300px) and (max-width: 575px) {

  .img-popup {
    width: 2rem;
  }

  .mt-popup{
    margin-top: -5px;
  }
}

@media screen and (max-width: 600px) {
  .ocultar-imgs{
      display:none;
    }
}

.icoInfo:hover~.dialogo{
  display: block;
}


.input-custom-error {
  color: red;
}

.input-custom-cp-error {
  font-size: 15px;
  color: red; 
}


.icoInfo{ 
  color: #2F80ED;
  cursor: pointer;
  margin-top: 45px; 
  margin-right: 10px;  
  font-size: 20px;
}

p.centrado {
  text-align: center;
}

::after, ::before {
  border: none!important;
}

.MuiTextField-root{
  min-width: 100%!important;
}

.MuiFormLabel-root {
  color: rgba(0, 0, 0, 0.87)!important;
}

.MuiFilledInput-root {
  border-style: none!important;
  overflow: hidden!important;
  background-color: transparent!important;    
  font-style: inherit!important;
  color: rgba(0, 0, 0, 0.87)!important;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: none!important;
  transition: none!important;
}

.MuiFilledInput-root:hover {
  border: none;
  background-color: transparent!important;
}

.MuiFilledInput-root .Mui-focused {
  border: none;
  background-color: transparent!important;
}

.add-symbol {
  margin-top: 20px;
  color: #FFFFFF;
  font-size: 75px;
  font-weight: 900;
}

.intermx-text {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
}

.alianza-text {
  font-weight: 400;
  font-size: 1.8em;
  text-transform: uppercase;
  font-family: 'Montserrat';
}

.app-transporte-text {
  font-weight: 600;
  font-size: 2em;
  text-transform: uppercase;
  font-family: 'Montserrat';
}

.tipo-persona-text {
  font-style: italic;
  font-weight: normal;
  font-size: 16px;
}

.no-auto-text{
  font-family: Montserrat;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  color: #0000FF;
}

.cotiza-text{
  font-family: Montserrat;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  color: #E7067F;
}

::placeholder {
  color: black;
}

.tamano-logo{
  width: 70px;
}

button.MuiAutocomplete-popupIndicator.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium {
  display: none;
}

.modal-individual-padding {
  padding : 20px 24px;
}

.modal-titulo {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;

  /* Black */

  color: #212121;
}

.modal-text {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-align: center;

  /* Black */

  color: #212121;
}

.modal-titulo-telefono {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;

  /* Black */

  color: #009dd0;
}

.textfab2 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;

  color: #039ecc;
}

.textfab {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;

  color: #ffffff;
}

.btn-flotante {
  z-index: 9999;
}