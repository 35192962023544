/*******************************************/
/*BEGIN - clases toolbar */
.toolbar{ width: 100%; height: 75px; background-color: #fff; }
.toolbar .table-sio{ height: 75px; }
.mat-icon-button.btn-icon.foto .profile-img{ width: 32px; height: 32px; }
.btn-icon{ background: 0 0!important; width: 32px !important; height: 32px !important; }
.color-icon{ color: #fff; }
.icono-40{ line-height: 32px !important; width: 32px !important; height: 32px !important; }
.logo{ cursor: pointer; max-height: 40px; vertical-align: middle; }
.profile-cont{ width: 100%; height: auto; padding-top: 10px; scroll-padding-bottom: 10px; }
.profile-photo{ background-color: #039ECC; -webkit-background-size: 60px 60px; background-size: 60px 60px; height: 60px; width: 60px; border: none; vertical-align: top; border-radius: 50%; overflow: hidden; text-align: center; margin: auto !important; }
.profile-t{ color: #202124; font: 500 16px/22px 'Montserrat', Google Sans,Roboto,RobotoDraft,Helvetica,Arial,sans-serif; letter-spacing: .29px; margin: 0; text-align: center; text-overflow: ellipsis; overflow: hidden; }
.profile-s{ color: #5f6368; font: 400 14px/19px 'Montserrat', Roboto,RobotoDraft,Helvetica,Arial,sans-serif; letter-spacing: normal; text-align: center; text-overflow: ellipsis; overflow: hidden; margin-top: 0px; margin-block-start: 0em; margin-block-end: 0em;}
.menu-option{ display: block; z-index: -1; position: absolute; left: 0; right: 0; top: 0; bottom: 0; opacity: 0; visibility: hidden; background: rgba(0,0,0,.4); transition: opacity .2s,visibility 0ms linear .2s,z-index 0ms linear .2s; }
.menu-option.active{ transition: opacity .2s,visibility 0ms; z-index: 4; opacity: 1; visibility: visible; }
.menu-option-nav{ z-index: 9000; background: #fff; position: fixed; right: 0; top: 0; bottom: 0; width: 100%; max-height: 300px !important; opacity: 0; visibility: hidden; overflow: hidden; transition: opacity .2s,visibility 0ms linear .2s,transform .5s cubic-bezier(.23,1,.32,1); transform: translate3d(290px,0,0); }
.menu-option.active .menu-option-nav{ transform: translateZ(0); opacity: 1; visibility: visible; transition: opacity .2s,visibility 0ms,transform .5s cubic-bezier(.23,1,.32,1); }
.menu-md-options{  display: none; }
.border-nav-left{ box-shadow: inset 1px 0 0 0 #e5e5e5; }
/*******************************************/
/*BEGIN - media max-width */
@media (max-width: 576px) { /*Small*/
}   
@media (max-width: 1024px) { /*Middle*/
    .display-mobil-tablet-none{ display: none !important; }
    .menu-md-options{ display: inline; }
}
@media (max-width: 992px) { /*Large*/
    .icon{ font-size: 20px; width: 20px; height: 20px; margin: auto auto; }
}
@media (max-width: 1200px) { /*XL*/
}
/*******************************************/
/*BEGIN - media min-width */
@media (min-width: 320px) { /*Small*/
    .w-ssm-50x{
        width: 50px;
    }
    .toolbar{
        padding: 0 10px;
    }
    .text-sm-center{
        text-align: center;
    }
}
@media (min-width: 576px) { /*Small*/
    /* .toolbar .table-sio{ max-width: 540px; } */
    .toolbar{
        padding: 0 20px;
    }
}   
@media (min-width: 768px) { /*Middle*/
    /* .toolbar .table-sio{ max-width: 720px; } */
    .toolbar{
        padding: 0 30px;
    }
    .text-md-left{
        text-align: left;
    }
}
@media (min-width: 992px) { /*Large*/
    /* .toolbar .table-sio{ max-width: 960px; } */
    .toolbar{
        padding: 0 50px;
    }
}
@media (min-width: 1200px) { /*XL*/
    /* .toolbar .table-sio{ max-width: 1140px; } */
    .w-lg-max-1010x{
        width: 100%;
        max-width: 1010px;
        margin: auto;
    }
    .w-lg-235x{
        width: 235px;
    }
    .toolbar{
        padding: 0 109px;
    }
}
.mat-button.mat-inter{
    min-width: 100px;
    margin-left: 10px;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
}
a.link{
    font-size: 16px;
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: middle;
    padding: 0 24px;
    color: rgba(0, 0, 0, 0.8);
    line-height: 23px;
    /* font-weight: 600; */
    letter-spacing: 1px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-bo
}
a.link:hover {
    cursor: pointer;
    color: #009DD0;
    text-decoration: underline;
}
.underline:hover{
    text-decoration: underline;
    cursor: pointer;
}

.btn-call{
    border: #DD0080 solid 1px;
    color: #DD0080;
    font-size: 10px;
    padding-left: 10px !important;
    padding-right: 10px !important;
    border-radius: 20px !important;
    margin-left: 5px;
    height: 29px;
    width: 112px;
    margin-right: 0px;
}

@media screen and (min-width: 600px) {
    .ocultar-btn{
      display:none;
    }
  }

  @media screen and (max-width: 600px) {
    .ocultar-login{
        display:none;
      }
  }

  .line-h{
      line-height: 30px;
  }

  .btn-close{
    color: #009DD0;
}
.mt-15{
    margin: 15px;
}

.button-sharet-link {
    background-color: #DD0080; 
    color: #fff;
    border-radius: 5px;
    font-weight: 500;
    font-size: 15px;
    border-color: #DD0080;
    width: 150px;
    cursor: pointer;
}

.container-help {
    height: 30px !important;
    border: 3px solid #DD0080;
    border-radius: 15px !important; 
    font-size: 10px;
}

.letter-color-help {
    color: #DD0080;
}


