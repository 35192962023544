/*******************************************/
/*BEGIN - clases footer */
.footer{ width: 100%; min-height: 500px; background-color:#282828 !important; padding: 30px 0px 20px 0px; }*/
.footer .inter-eslogan{ font-size: 22px; line-height: 28px; }
p{
    max-width: 1140px;
    margin: auto;
}
.icon-redes{
    width: 20px;
    height: 20px;
    margin-right: 5px;
    vertical-align: middle;
}

.link{
    cursor: pointer;
}

.link:hover{
    text-decoration: underline;
}

.logoInter{
    height: 60px;
    margin: auto 10px;
}
.logoInterMX{
    width: 150px;
}
.logoSsl{
    width: 100px;
}

.logoSha{
    width: 80px;
    margin: auto 10px;
}

.footerlogo{
    vertical-align: middle;
    text-align: center;
}
.title-h1{
    line-height: 1.9rem;
}
.line-white{
    width: 100%;
    height: 5px;
    background-color: #ffffff;
    margin: 10px auto;
}
h3.destacado {
    font-size: 21px;
}
.mb-1rem{
    margin-bottom: 1rem;
}
.mb-2rem{
    margin-bottom: 2rem;
}
.redes{
    width: 18px;
    height: 18px;
    margin: 10px;
}
.banner-tpa{
    width: 100%;
    min-height: 120px;
    background-color: #5b3587;
    padding-top: 10px;
    padding-bottom: 10px;
}
.banner-tpa .container, .banner-tpa .container .row{ min-height: 120px; }
p{ color: #fff; }
a{ color: #fff; }
.imgSolTpa{ max-width: 240px; max-height: 60px; }

.p-footer{
  box-sizing: border-box;
  color: #6e6f70;
  font-family: Montserrat;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  height: 34px;
  line-height: 34px;
  margin-bottom: 0px;
  margin-left: 320px;
  margin-right: 612.033px;
  margin-top: 0px;
  text-align: center;
  width: 400px

}

.footer-new{
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}


.footer-servicios {
  font-style:normal;
  font-size:0.8rem;
  color:#bdbebf;
  margin-bottom:40px;
}

.clickable{
  font-style:normal;
  font-size:15px;
}

.footer-logo-tienda {
  width:10pc;
  height:3pc;
  margin:0.5pc;
  margin-left:0px;
}

.footer-siguenos {
  font-style:normal;
  font-size:0.8rem;
  color:#bdbebf;
  margin-bottom:15px;
  margin-top:30px;
}