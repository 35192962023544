.body-estruct{
    width: 100%;
    min-height: calc(100vh - 340px);
    position: relative;
    background-color: #E5E5E5;
}
.title-section{
    color: #039ECC;
    font-weight: bold;
    font-size: 32px;
    margin: 10px 0px;
}
.icon-section{
    background-color: #039ECC;
    color: white;
    padding: 5px;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    text-align: center;
    font-size: 18px;
    margin-left: 30px;
    cursor: pointer;
}
.pasarela {
  enable-background:new 0 0 750 471 !important;
}

.lbl-etiqueta {
    width: 100%;
    display: block;
    color: rgba(0,0,0,.64)!important;
    line-height: 1.125;
    font: inherit;
    font-size: 8pt;
    font-family: Roboto,"Helvetica Neue",sans-serif;
    margin-top: 10px;
}
.lbl-value {
    width: 100%;
    display: block;
    margin-bottom: 10px;
}
.btn-back {
    color: #039ECC;
    font-size: 16px;
    font-family: "Montserrat", Roboto,"Helvetica Neue",sans-serif;
    cursor: pointer;
    margin: 10px 0px;
}
.lbl-separate {
    color: #818181;
    float: left;
    font-size: 18pt;
    font-family: "Segoe UI";
    margin: 0px 10px 0px 10px;
}
.lbl-paso {
    color: #818181;
    float: left;
    font-size: 14px;
}
.icon-paso-num {
    background-color: #535150;
    color: white;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    text-align: center;
    margin-right: 10px;
    float: left;
    font-size: 14px;
}
.lbl-separate-disabled {
    color: #BBBBBB;
    float: left;
    font-size: 18pt;
    font-family: "Segoe UI";
    margin: 0px 10px 0px 10px;
}
.lbl-paso-disabled {
    color: #BBBBBB;
    float: left;
    font-size: 14px;;
}
.icon-paso-num-disabled {
    background-color: #BBBBBB;
    color: white;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    text-align: center;
    margin-right: 10px;
    float: left;
    font-size: 14px;
}
.lbl-subtitle {
    color: #222222;
    font-size: 16px;
    font-family: "Montserrat", Roboto,"Helvetica Neue",sans-serif;
    text-align: center;
    margin: 25px 0 25px 0;
}
.lbl-resumen {
  color: #222222;
  font-size: 24px;
  font-family: "Montserrat", Roboto,"Helvetica Neue",sans-serif;
  text-align: center;
  margin: 25px 0 25px 0;
}
.lbl-resumen .link {
  color: #009DD0;
}
.lbl-resumen .link:hover {
  cursor: pointer;
}

/*
************************************************************************************************************************************
************************************************************************************************************************************
************************************************************************************************************************************
                                                    DESDE AQUI CSS MIGRADO
************************************************************************************************************************************
************************************************************************************************************************************
************************************************************************************************************************************
*/

.ccicon {
    height: 30px;
    position: absolute;
    right: 30px;
    top: calc(50% - 18px);
    width: 60px;
}


/* CREDIT CARD IMAGE STYLING */
.preload * {
    transition: none !important;
    -moz-transition: none !important;
    -ms-transition: none !important;
    -o-transition: none !important;
  }

  .ccsinglediv {
    position: absolute;
    right: 15px;
    top: 20px;
  }
  
  .ccsinglesvg {
    width: 100px;
    max-height: 40px;
  }


.creditcard svg#cardfront,
.creditcard svg#cardback {
  width: 100%;
  -webkit-box-shadow: 1px 5px 6px 0px black;
  box-shadow: 1px 5px 6px 0px black;
  border-radius: 22px;
}

#generatecard{
  cursor: pointer;
  float: right;
  font-size: 12px;
  color: #fff;
  padding: 2px 4px;
  background-color: #909090;
  border-radius: 4px;
  cursor: pointer;
  float:right;
}

/* CHANGEABLE CARD ELEMENTS */
.creditcard .lightcolor,
.creditcard .darkcolor {
  -webkit-transition: fill .5s;
  transition: fill .5s;
}

.creditcard .lightblue {
  fill: #03A9F4;
}

.creditcard .lightbluedark {
  fill: #0288D1;
}

.creditcard .red {
  fill: #ef5350;
}

.creditcard .reddark {
  fill: #d32f2f;
}

.creditcard .purple {
  fill: #ab47bc;
}

.creditcard .purpledark {
  fill: #7b1fa2;
}

.creditcard .cyan {
  fill: #26c6da;
}

.creditcard .cyandark {
  fill: #0097a7;
}

.creditcard .green {
  fill: #98C8BE;
}

.creditcard .greendark {
  fill: #6cbd80;
}

.creditcard .lime {
  fill: #d4e157;
}

.creditcard .limedark {
  fill: #afb42b;
}

.creditcard .yellow {
  fill: #ffeb3b;
}

.creditcard .yellowdark {
  fill: #f9a825;
}

.creditcard .orange {
  fill: #ff9800;
}

.creditcard .orangedark {
  fill: #ef6c00;
}

.creditcard .grey {
  fill: #bdbdbd;
}

.creditcard .greydark {
  fill: #616161;
}

/* FRONT OF CARD */
#svgname {
  text-transform: uppercase;
}

#cardfront .st2 {
  fill: #FFFFFF;
}

#cardfront .st3 {
  font-family: 'Source Code Pro', monospace;
  font-weight: 600;
}

#cardfront .st4 {
  font-size: 54.7817px;
}

#cardfront .st5 {
  font-family: 'Source Code Pro', monospace;
  font-weight: 400;
}

#cardfront .st6 {
  font-size: 22px;
}

#cardfront .st7 {
  opacity: 0.6;
  fill: #FFFFFF;
}

#cardfront .st8 {
  font-size: 24px;
}

#cardfront .st9 {
  font-size: 36.5498px;
}

#cardfront .st10 {
  font-family: 'Source Code Pro', monospace;
  font-weight: 300;
}

#cardfront .st11 {
  font-size: 16.1716px;
}

#cardfront .st12 {
  fill: #4C4C4C;
}

/* BACK OF CARD */
#cardback .st0 {
  fill: none;
  stroke: #0F0F0F;
  stroke-miterlimit: 10;
}

#cardback .st2 {
  fill: #111111;
}

#cardback .st3 {
  fill: #F2F2F2;
}

#cardback .st4 {
  fill: #D8D2DB;
}

#cardback .st5 {
  fill: #C4C4C4;
}

#cardback .st6 {
  font-family: 'Source Code Pro', monospace;
  font-weight: 400;
}

#cardback .st7 {
  font-size: 27px;
}

#cardback .st8 {
  opacity: 0.6;
}

#cardback .st9 {
  fill: #FFFFFF;
}

#cardback .st10 {
  font-size: 24px;
}

#cardback .st11 {
  fill: #EAEAEA;
}

#cardback .st12 {
  font-family: 'Rock Salt', cursive;
}

#cardback .st13 {
  font-size: 37.769px;
}

/* FLIP ANIMATION */
.container {
    perspective: 1000px;
}
  
  .creditcard {
    width: 100%;
    max-width: 500px;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    transition: -webkit-transform 0.6s;
    -webkit-transition: -webkit-transform 0.6s;
    transition: transform 0.6s;
    transition: transform 0.6s, -webkit-transform 0.6s;
    cursor: pointer;
  }
  
  .creditcard .front,
  .creditcard .back {
    position: absolute;
    width: 100%;
    max-width: 500px;
    backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-font-smoothing: antialiased;
    color: #47525d;
  }
  
  .creditcard .back {
    transform: rotateY(180deg);
    transform: rotateY(180deg);
  }
  
  .creditcard.flipped {
    transform: rotateY(180deg);
    transform: rotateY(180deg);
  }

  .font-terminos{
    font-size: 12px !important;
    font-weight: 600;
    color: #616161;
  }
  a{
    text-decoration: none;
    color: #009DD0;
  }

  .iframe-pago {
    overflow: hidden;
  }

  @media screen and (max-width: 600px) {
    .ocultar-btn{
      display:none;
    }
}
@media (min-width: 280px) { /*ssm*/
  .creditcard{
    width: 80%;
    min-height: 80px;
  }
    .w-ssm-100{
        width: 100%;
    } 
}
@media (min-width: 320px) { /*ssm*/
  .creditcard{
    width: 80%;
    min-height: 80px;
  }
    .w-ssm-100{
        width: 100%;
    } 
}
@media (min-width: 414px) { /*ssm*/
  .creditcard{
    width: 70%;
    min-height: 80px;
  }
    .w-ssm-100{
        width: 100%;
    } 
}

@media (min-width: 540px) { /*sm*/
    .creditcard{
    width: 50%;
    min-height: 100px;
  }
    .w-sm-100{
        width: 100%;
    } 
}
@media (min-width: 768px) { /*md*/
  .creditcard{
    width: 30%;
    min-height: 100px;
  }
    .pasarelaTarjeta {
      float: left !important;
    }
    .w-md-50{
        width: 50%;
    }
    .w-md-45{
        width: 45%;
    }
}
@media (min-width: 992px) { /*lg*/
  .creditcard{
    width: 100%;
    min-height: 300px;
  }
  .pasarelaTarjeta {
      float: left !important;
    }
}
@media (min-width: 1200px) { /*xl*/
  .creditcard{
    width: 100%;
    min-height: 300px;
  }
  .pasarelaTarjeta {
      float: left !important;
    }
}
@media (min-width: 1280px) { /*xl*/
  .creditcard{
    width: 100%;
    min-height: 300px;
  }
  .pasarelaTarjeta {
      float: left !important;
    }
}

.MuiCheckbox-root.Mui-checked,
.MuiRadio-root.Mui-checked {
  color: #DD0080!important;
}

.a-color{
  color: #DD0080!important;
  text-decoration: none;
}

.digi-cert-text{
  font-family: Montserrat;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  color: #6F7271;
}

input[type="text"], 
input[type="password"], 
input[type="number"], 
input[type="email"], 
input[type="date"] {
  background-color: #fff!important;
}

.tarjeta-form {
  max-width: 880px!important;
}