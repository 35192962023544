/*@import "~@angular/material/prebuilt-themes/indigo-pink.css";*/
@import "./assets/css/indigo-pink.css";
/* @import url("https://fonts.googleapis.com/css?family=Montserrat:400,900"); */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;900&display=swap");
/* You can add global styles to this file, and also import other style files */
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}
body {
  margin: 0;
  font-size: 16px;
  line-height: 1.5rem;
  /* font-family: 'Montserrat', Roboto, "Helvetica Neue", sans-serifs;  */
  font-family: "Montserrat", sans-serif;
}
.body-estruct {
  width: 100%;
  min-height: calc(100vh - 75px);
  position: relative;
  background-color: #f5f5f5;
}
/*******************************************/
/*BEGIN - button material */
/*******************************************/
.mat-button,
.mat-flat-button,
.mat-raised-button,
.mat-stroked-button {
  font-family: "Montserrat", Roboto, "Helvetica Neue", sans-serifs;
  cursor: pointer;
  min-width: 250px;
  min-height: 40px;
  font-weight: bold;
  border-radius: 6px !important;
}
.mat-checkbox {
  font-family: "Montserrat", Roboto, "Helvetica Neue", sans-serifs;
  cursor: pointer;
}
.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.mat-radio-outer-circle {
  height: 25px !important;
  width: 25px !important;
  border-width: 1px !important;
}
.mat-radio-inner-circle {
  height: 25px !important;
  width: 25px !important;
}
.mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}
/*Coche Seguro INTER*/
.link-inter {
  color: #dd0080;
  cursor: pointer;
}
.mat-button.mat-inter,
.mat-icon-button.mat-inter {
  color: #dd0080;
  background-color: transparent;
}
/******************************/
.mat-checkbox-checked.mat-inter .mat-checkbox-ripple .mat-ripple-element {
  opacity: 0.03 !important;
  background-color: #dd0080 !important;
}
.mat-checkbox-checked.mat-inter .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-inter .mat-checkbox-background {
  background-color: #dd0080 !important;
}
.mat-checkbox-ripple .mat-ripple-element,
.mat-checkbox-checked.mat-inter .mat-checkbox-background {
  background-color: #dd0080 !important;
}
.mat-radio-button.mat-inter .mat-radio-ripple .mat-ripple-element {
  opacity: 0 !important; /*click effect color change*/
  background-color: #dd0080 !important;
}
.mat-radio-button.mat-inter .mat-radio-inner-circle {
  background-color: #dd0080 !important; /*inner circle color change*/
}
.mat-radio-button.mat-radio-checked.mat-inter .mat-radio-outer-circle {
  border-color: #dd0080 !important; /*outer ring color change*/
}
/******************************/
.mat-button.mat-inter {
  color: #dd0080;
}
.mat-flat-button.mat-inter {
  background-color: #dd0080;
  color: white;
}
.mat-raised-button.mat-inter {
  background-color: transparent;
  color: #dd0080;
}
.mat-raised-button.mat-inter:hover {
  background-color: #dd0080;
  color: white;
}
.mat-stroked-button.mat-inter {
  background-color: transparent;
  color: #dd0080;
  border: 2px solid #dd0080;
}
.mat-stroked-button.mat-inter:hover {
  background-color: #dd0080;
  color: white;
  border: 2px solid #dd0080;
}
.mat-button.mat-inter {
  border: 2px solid transparent;
}
.mat-button.mat-inter:hover {
  border: 2px solid #dd0080;
}
.mat-button.mat-inter:hover,
.mat-button.mat-inter .mat-button-focus-overlay {
  background-color: transparent;
  background: transparent;
}
.mat-flat-button.mat-inter mat-icon,
.mat-raised-button.mat-inter mat-icon,
.mat-stroked-button.mat-inter mat-icon,
.mat-flat-button.mat-inter label,
.mat-raised-button.mat-inter label,
.mat-stroked-button.mat-inter label {
  cursor: pointer;
}
/*Coche Seguro copsis*/
.link-copsis {
  color: #f57c00;
  cursor: pointer;
}
.mat-button.mat-copsis,
.mat-icon-button.mat-copsis {
  color: #f57c00;
  background-color: transparent;
}
.mat-button.mat-copsis {
  color: #f57c00;
}
.mat-flat-button.mat-copsis {
  background-color: #f57c00;
  color: white;
}
.mat-raised-button.mat-copsis {
  background-color: transparent;
  color: #f57c00;
}
.mat-raised-button.mat-copsis:hover {
  background-color: #f57c00;
  color: white;
}
.mat-stroked-button.mat-copsis {
  background-color: transparent;
  color: #f57c00;
  border: 2px solid #f57c00;
}
.mat-stroked-button.mat-copsis:hover {
  background-color: #f57c00;
  color: white;
  border: 2px solid #f57c00;
}
.mat-button.mat-copsis {
  border: 2px solid transparent;
}
.mat-button.mat-copsis:hover {
  border: 2px solid #f57c00;
}
.mat-button.mat-copsis:hover,
.mat-button.mat-copsis .mat-button-focus-overlay {
  background-color: transparent;
  background: transparent;
}
.mat-flat-button.mat-copsis mat-icon,
.mat-raised-button.mat-copsis mat-icon,
.mat-stroked-button.mat-copsis mat-icon,
.mat-flat-button.mat-copsis label,
.mat-raised-button.mat-copsis label,
.mat-stroked-button.mat-copsis label {
  cursor: pointer;
}
/******************************/
.mat-checkbox-checked.mat-copsis .mat-checkbox-ripple .mat-ripple-element {
  opacity: 0.03 !important;
  background-color: #f57c00 !important;
}
.mat-checkbox-checked.mat-copsis .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-copsis .mat-checkbox-background {
  background-color: #f57c00 !important;
}
.mat-checkbox-ripple .mat-ripple-element,
.mat-checkbox-checked.mat-copsis .mat-checkbox-background {
  background-color: #f57c00 !important;
}
.mat-radio-button.mat-copsis .mat-radio-ripple .mat-ripple-element {
  opacity: 0 !important; /*click effect color change*/
  background-color: #f57c00 !important;
}
.mat-radio-button.mat-copsis .mat-radio-inner-circle {
  background-color: #f57c00 !important; /*inner circle color change*/
}
.mat-radio-button.mat-radio-checked.mat-copsis .mat-radio-outer-circle {
  border-color: #f57c00 !important; /*outer ring color change*/
}
/******************************/
/*Coche Seguro uvm*/
.link-uvm {
  color: #e72400;
  cursor: pointer;
}
.mat-button.mat-uvm,
.mat-icon-button.mat-uvm {
  color: #e72400;
  background-color: transparent;
}
.mat-button.mat-uvm {
  color: #e72400;
}
.mat-flat-button.mat-uvm {
  background-color: #e72400;
  color: white;
}
.mat-raised-button.mat-uvm {
  background-color: transparent;
  color: #e72400;
}
.mat-raised-button.mat-uvm:hover {
  background-color: #e72400;
  color: white;
}
.mat-stroked-button.mat-uvm {
  background-color: transparent;
  color: #e72400;
  border: 2px solid #e72400;
}
.mat-stroked-button.mat-uvm:hover {
  background-color: #e72400;
  color: white;
  border: 2px solid #e72400;
}
.mat-button.mat-uvm {
  border: 2px solid transparent;
}
.mat-button.mat-uvm:hover {
  border: 2px solid #e72400;
}
.mat-button.mat-uvm:hover,
.mat-button.mat-uvm .mat-button-focus-overlay {
  background-color: transparent;
  background: transparent;
}
.mat-flat-button.mat-uvm mat-icon,
.mat-raised-button.mat-uvm mat-icon,
.mat-stroked-button.mat-uvm mat-icon,
.mat-flat-button.mat-uvm label,
.mat-raised-button.mat-uvm label,
.mat-stroked-button.mat-uvm label {
  cursor: pointer;
}
/******************************/
.mat-checkbox-checked.mat-uvm .mat-checkbox-ripple .mat-ripple-element {
  opacity: 0.03 !important;
  background-color: #e72400 !important;
}
.mat-checkbox-checked.mat-uvm .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-uvm .mat-checkbox-background {
  background-color: #e72400 !important;
}
.mat-checkbox-ripple .mat-ripple-element,
.mat-checkbox-checked.mat-uvm .mat-checkbox-background {
  background-color: #e72400 !important;
}
.mat-radio-button.mat-uvm .mat-radio-ripple .mat-ripple-element {
  opacity: 0 !important; /*click effect color change*/
  background-color: #e72400 !important;
}
.mat-radio-button.mat-uvm .mat-radio-inner-circle {
  background-color: #e72400 !important; /*inner circle color change*/
}
.mat-radio-button.mat-radio-checked.mat-uvm .mat-radio-outer-circle {
  border-color: #e72400 !important; /*outer ring color change*/
}
/******************************/
/*Coche Seguro unitec*/
.link-unitec {
  color: #006fba;
  cursor: pointer;
}
.mat-button.mat-unitec,
.mat-icon-button.mat-unitec {
  color: #006fba;
  background-color: transparent;
}
.mat-button.mat-unitec {
  color: #006fba;
}
.mat-flat-button.mat-unitec {
  background-color: #006fba;
  color: white;
}
.mat-raised-button.mat-unitec {
  background-color: transparent;
  color: #006fba;
}
.mat-raised-button.mat-unitec:hover {
  background-color: #006fba;
  color: white;
}
.mat-stroked-button.mat-unitec {
  background-color: transparent;
  color: #006fba;
  border: 2px solid #006fba;
}
.mat-stroked-button.mat-unitec:hover {
  background-color: #006fba;
  color: white;
  border: 2px solid #006fba;
}
.mat-button.mat-unitec {
  border: 2px solid transparent;
}
.mat-button.mat-unitec:hover {
  border: 2px solid #006fba;
}
.mat-button.mat-unitec:hover,
.mat-button.mat-unitec .mat-button-focus-overlay {
  background-color: transparent;
  background: transparent;
}
.mat-flat-button.mat-unitec mat-icon,
.mat-raised-button.mat-unitec mat-icon,
.mat-stroked-button.mat-unitec mat-icon,
.mat-flat-button.mat-unitec label,
.mat-raised-button.mat-unitec label,
.mat-stroked-button.mat-unitec label {
  cursor: pointer;
}
/******************************/
.mat-checkbox-checked.mat-unitec .mat-checkbox-ripple .mat-ripple-element {
  opacity: 0.03 !important;
  background-color: #006fba !important;
}
.mat-checkbox-checked.mat-unitec .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-unitec .mat-checkbox-background {
  background-color: #006fba !important;
}
.mat-checkbox-ripple .mat-ripple-element,
.mat-checkbox-checked.mat-unitec .mat-checkbox-background {
  background-color: #006fba !important;
}
.mat-radio-button.mat-unitec .mat-radio-ripple .mat-ripple-element {
  opacity: 0 !important; /*click effect color change*/
  background-color: #006fba !important;
}
.mat-radio-button.mat-unitec .mat-radio-inner-circle {
  background-color: #006fba !important; /*inner circle color change*/
}
.mat-radio-button.mat-radio-checked.mat-unitec .mat-radio-outer-circle {
  border-color: #006fba !important; /*outer ring color change*/
}
/******************************/
/*******************************************/
/*END - button material */
/*******************************************/
/**************************************family*****/
/*BEGIN - titulo, destacado y cuerpo */
h1.titulo {
  font-size: 24px;
  font-weight: 900;
  line-height: 29px;
}
h3.destacado {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin: 0px auto 15px auto;
}
p.cuerpo {
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
}
div.titulo-seccion {
  font-size: 21px;
  font-style: normal;
  line-height: 26px;
  font-weight: bold;
  text-align: center;
}
hr {
  border-style: solid;
  color: #d4cfcf;
  border-width: 0.5px;
}
.font-bold {
  font-weight: bold;
}
.font-100 {
  font-weight: 100 !important;
}
.font-200 {
  font-weight: 200 !important;
}
.font-300 {
  font-weight: 300 !important;
}
.font-400 {
  font-weight: 400 !important;
}
.font-500 {
  font-weight: 500 !important;
}
.font-600 {
  font-weight: 600 !important;
}
.font-700 {
  font-weight: 700 !important;
}
.font-800 {
  font-weight: 800 !important;
}
.font-900 {
  font-weight: 900 !important;
}
/*******************************************/
/*BEGIN - table-sio */
.table-sio {
  display: table;
}
.header-sio {
  display: table-header-group;
  font-weight: 500;
}
.row-sio {
  display: table-row;
}
.cell-sio {
  display: table-cell;
}
.footer-sio {
  display: table-footer-group;
}
.body-sio {
  display: table-row-group;
}
.table-sio.vertical-middle .cell-sio {
  vertical-align: middle;
}
.table-sio.vertical-top .cell-sio {
  vertical-align: top;
}
.table-border-sio {
  display: table;
  border: 1px solid #ebedf0;
  border-collapse: collapse;
}
.table-border-sio.vertical-middle .cell-sio {
  vertical-align: middle;
}
.table-border-sio.vertical-top .cell-sio {
  vertical-align: top;
}
.table-border-sio > .row-sio {
  height: 40px;
  border: 1px solid #ebedf0;
  border-collapse: collapse;
}
.hidden-absolute {
  visibility: hidden;
  position: absolute;
  pointer-events: none;
}
.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/*******************************************/
/*BEGIN - float */
.float-left {
  float: left;
}
.float-right {
  float: right;
}
/*************************/
/*BEGIN - width */
.w-5 {
  width: 5% !important;
}
.w-10 {
  width: 10% !important;
}
.w-15 {
  width: 15% !important;
}
.w-20 {
  width: 20% !important;
}
.w-25 {
  width: 25% !important;
}
.w-30 {
  width: 30% !important;
}
.w-35 {
  width: 35% !important;
}
.w-40 {
  width: 40% !important;
}
.w-45 {
  width: 45% !important;
}
.w-50 {
  width: 50% !important;
}
.w-55 {
  width: 55% !important;
}
.w-60 {
  width: 60% !important;
}
.w-65 {
  width: 65% !important;
}
.w-70 {
  width: 70% !important;
}
.w-75 {
  width: 75% !important;
}
.w-80 {
  width: 80% !important;
}
.w-85 {
  width: 85% !important;
}
.w-90 {
  width: 90% !important;
}
.w-95 {
  width: 95% !important;
}
.w-100 {
  width: 100% !important;
}
.w-40x {
  width: 40px !important;
}
.w-50x {
  width: 50px !important;
}
.w-65x {
  width: 65px !important;
}
.w-75x {
  width: 75px !important;
}
.w-100x {
  width: 100px !important;
}
.w-125x {
  width: 125px !important;
}
.w-150x {
  width: 150px !important;
}
.w-175x {
  width: 175px !important;
}
.w-200x {
  width: 200px !important;
}
.w-225x {
  width: 225px !important;
}
.w-250x {
  width: 250px !important;
}
.w-275x {
  width: 275px !important;
}
.w-300x {
  width: 300px !important;
}
/*******************************************/
/*BEGIN - Height*/
.h-5 {
  height: 5% !important;
}
.h-10 {
  height: 10% !important;
}
.h-15 {
  height: 15% !important;
}
.h-20 {
  height: 20% !important;
}
.h-25 {
  height: 25% !important;
}
.h-30 {
  height: 30% !important;
}
.h-35 {
  height: 35% !important;
}
.h-40 {
  height: 40% !important;
}
.h-45 {
  height: 45% !important;
}
.h-50 {
  height: 50% !important;
}
.h-55 {
  height: 55% !important;
}
.h-60 {
  height: 60% !important;
}
.h-65 {
  height: 65% !important;
}
.h-70 {
  height: 70% !important;
}
.h-75 {
  height: 75% !important;
}
.h-80 {
  height: 80% !important;
}
.h-85 {
  height: 85% !important;
}
.h-90 {
  height: 90% !important;
}
.h-95 {
  height: 95% !important;
}
.h-100 {
  height: 100% !important;
}
.h-10x {
  height: 10px !important;
}
.h-20x {
  height: 20px !important;
}
.h-30x {
  height: 30px !important;
}
.h-40x {
  height: 40px !important;
}
.h-50x {
  height: 50px !important;
}
.h-70x {
  height: 70px !important;
}
.h-75x {
  height: 75px !important;
}
.h-80x {
  height: 80px !important;
}
.h-100x {
  height: 100px !important;
}
.h-125x {
  height: 125px !important;
}
.h-150x {
  height: 150px !important;
}
.h-175x {
  height: 175px !important;
}
.h-200x {
  height: 200px !important;
}
.h-225x {
  height: 225px !important;
}
.h-250x {
  height: 250px !important;
}
.h-275x {
  height: 275px !important;
}
.h-300x {
  height: 300px !important;
}
.h-325x {
  height: 325px !important;
}
.h-350x {
  height: 350px !important;
}
.h-375x {
  height: 375px !important;
}
.h-400x {
  height: 400px !important;
}
/*******************************************/
/*BEGIN - padding px */
.p-1rem {
  padding: 1rem !important;
}
.p-0x {
  padding: 0px !important;
}
.p-5x {
  padding: 5px !important;
}
.p-10x {
  padding: 10px !important;
}
.p-15x {
  padding: 15px !important;
}
.p-20x {
  padding: 20px !important;
}
.p-30x {
  padding: 30px !important;
}
.p-40x {
  padding: 40px !important;
}
.pt-1rem {
  padding-top: 1rem !important;
}
.pt-0x {
  padding-top: 0px !important;
}
.pt-5x {
  padding-top: 5px !important;
}
.pt-10x {
  padding-top: 10px !important;
}
.pt-15x {
  padding-top: 15px !important;
}
.pt-20x {
  padding-top: 20px !important;
}
.pt-30x {
  padding-top: 30px !important;
}
.pt-40x {
  padding-top: 40px !important;
}
.pt-50x {
  padding-top: 50px !important;
}
.pt-75x {
  padding-top: 75px !important;
}
.pt-100x {
  padding-top: 100px !important;
}
.pb-1rem {
  padding-bottom: 1rem !important;
}
.pb-0x {
  padding-bottom: 0px !important;
}
.pb-5x {
  padding-bottom: 5px !important;
}
.pb-10x {
  padding-bottom: 10px !important;
}
.pb-15x {
  padding-bottom: 15px !important;
}
.pb-20x {
  padding-bottom: 20px !important;
}
.pb-30x {
  padding-bottom: 30px !important;
}
.pb-40x {
  padding-bottom: 40px !important;
}
.px-0x {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.px-5x {
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.px-10x {
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.px-15x {
  padding-left: 15px !important;
  padding-right: 15px !important;
}
.px-20x {
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.px-30x {
  padding-left: 30px !important;
  padding-right: 30px !important;
}
.px-40x {
  padding-left: 40px !important;
  padding-right: 40px !important;
}
.py-0x {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.py-5x {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.py-10x {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.py-15x {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}
.py-20x {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}
.py-30x {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}
.py-40x {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}
.py-50x {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}
.py-60x {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}
.py-70x {
  padding-top: 70px !important;
  padding-bottom: 70px !important;
}
.py-80x {
  padding-top: 80px !important;
  padding-bottom: 80px !important;
}
.py-90x {
  padding-top: 90px !important;
  padding-bottom: 90px !important;
}
.py-100x {
  padding-top: 100px !important;
  padding-bottom: 100px !important;
}
.py-150x {
  padding-top: 150px !important;
  padding-bottom: 150px !important;
}
.py-200x {
  padding-top: 200px !important;
  padding-bottom: 200px !important;
}
.pl-0x {
  padding-left: 0px !important;
}
.pl-5x {
  padding-left: 5px !important;
}
.pl-10x {
  padding-left: 10px !important;
}
.pl-15x {
  padding-left: 15px !important;
}
.pl-20x {
  padding-left: 20px !important;
}
.pl-30x {
  padding-left: 30px !important;
}
.pl-40x {
  padding-left: 40px !important;
}
.pr-0x {
  padding-right: 0px !important;
}
.pr-5x {
  padding-right: 5px !important;
}
.pr-10x {
  padding-right: 10px !important;
}
.pr-15x {
  padding-right: 15px !important;
}
.pr-20x {
  padding-right: 20px !important;
}
.pr-30x {
  padding-right: 30px !important;
}
.pr-40x {
  padding-right: 40px !important;
}
/*******************************************/
/*BEGIN - margen px */
.m-1rem {
  margin: 1rem !important;
}
.m-0x {
  margin: 0px !important;
}
.m-5x {
  margin: 5px !important;
}
.m-10x {
  margin: 10px !important;
}
.m-15x {
  margin: 15px !important;
}
.m-20x {
  margin: 20px !important;
}
.m-30x {
  margin: 30px !important;
}
.mt-1rem {
  margin-top: 1rem !important;
}
.mt-0x {
  margin-top: 0px !important;
}
.mt-3x {
  margin-top: 3px !important;
}
.mt-5x {
  margin-top: 5px !important;
}
.mt-10x {
  margin-top: 10px !important;
}
.mt-15x {
  margin-top: 15px !important;
}
.mt-20x {
  margin-top: 20px !important;
}
.mt-30x {
  margin-top: 30px !important;
}
.mt-40x {
  margin-top: 40px !important;
}
.mr-1rem {
  margin-right: 1rem !important;
}
.mr-0x {
  margin-right: 0px !important;
}
.mr-5x {
  margin-right: 5px !important;
}
.mr-10x {
  margin-right: 10px !important;
}
.mr-15x {
  margin-right: 15px !important;
}
.mr-20x {
  margin-right: 20px !important;
}
.mr-30x {
  margin-right: 30px !important;
}
.mr-40x {
  margin-right: 40px !important;
}
.mb-1rem {
  margin-bottom: 1rem !important;
}
.mb-0x {
  margin-bottom: 0px !important;
}
.mb-5x {
  margin-bottom: 5px !important;
}
.mb-10x {
  margin-bottom: 10px !important;
}
.mb-15x {
  margin-bottom: 15px !important;
}
.mb-20x {
  margin-bottom: 20px !important;
}
.mb-30x {
  margin-bottom: 30px !important;
}
.mb-40x {
  margin-bottom: 40px !important;
}
.ml-1rem {
  margin-left: 1rem !important;
}
.ml-0x {
  margin-left: 0px !important;
}
.ml-5x {
  margin-left: 5px !important;
}
.ml-10x {
  margin-left: 10px !important;
}
.ml-15x {
  margin-left: 15px !important;
}
.ml-20x {
  margin-left: 20px !important;
}
.ml-30x {
  margin-left: 30px !important;
}
.ml-40x {
  margin-left: 40px !important;
}
.mx-0x {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.mx-5x {
  margin-left: 5px !important;
  margin-right: 5px !important;
}
.mx-10x {
  margin-left: 10px !important;
  margin-right: 10px !important;
}
.mx-15x {
  margin-left: 15px !important;
  margin-right: 15px !important;
}
.mx-20x {
  margin-left: 20px !important;
  margin-right: 20px !important;
}
.mx-30x {
  margin-left: 30px !important;
  margin-right: 30px !important;
}
.my-0x {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.my-5x {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}
.my-10x {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
.my-15x {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}
.my-20x {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}
.my-30x {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}
.my-40x {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}
.my-50x {
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}
/*******************************************/
/*BEGIN - no padding y margin */
.not-pmx {
  margin-left: -1.25rem !important;
  margin-right: -1.25rem !important;
}
.pm-0x {
  padding-left: 0px !important;
  padding-right: 0px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
}
/*******************************************/
/*BEGIN - alineacion de texto */
.text-center {
  text-align: center !important;
}
.text-right {
  text-align: right !important;
}
.text-left {
  text-align: left !important;
}
.text-top {
  vertical-align: top !important;
}
.text-bottom {
  margin-top: 20px;
  align-items: center !important;
}
.text-middle {
  vertical-align: middle !important;
}
/*******************************************/
.label-etiqueta {
  color: #757575;
  cursor: pointer;
  display: block;
  font-size: 0.875rem;
  font-weight: normal;
  line-height: 1.5;
  margin-bottom: 0;
}
.label-value {
  color: #000;
  cursor: pointer;
  display: block;
  font-size: 0.875rem;
  font-weight: normal;
  line-height: 1.5;
  margin-bottom: 0;
}
/*******************************************/
/*BEGIN - materal-icon */
.material-icons.fs-12 {
  font-size: 12px;
  width: 12px;
  height: 12px;
}
.material-icons.fs-14 {
  font-size: 14px;
  width: 14px;
  height: 14px;
}
.material-icons.fs-16 {
  font-size: 16px;
  width: 16px;
  height: 16px;
}
.material-icons.fs-18 {
  font-size: 18px;
  width: 18px;
  height: 18px;
}
.material-icons.fs-24 {
  font-size: 24px;
  width: 24px;
  height: 24px;
}
.material-icons.fs-30 {
  font-size: 30px;
  width: 30px;
  height: 30px;
}
.material-icons.fs-36 {
  font-size: 36px;
  width: 36px;
  height: 36px;
}
.material-icons.fs-48 {
  font-size: 48px;
  width: 48px;
  height: 48px;
}
/*******************************************/
/*BEGIN - tarjeta poliza */
.tarjeta-poliza {
  width: calc(100% - 10px);
  min-width: 200px;
  max-width: 350px;
  position: relative;
  min-height: 380px;
  height: auto;
  margin: 10px auto;
  padding: 5px 10px;
  background-color: white;
  border-radius: 8px;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
    0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12);
}
.tarjeta-poliza .label-etiqueta,
.tarjeta-poliza .label-value {
  cursor: default;
}
.tarjeta-poliza:hover {
  -webkit-box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
    0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
.tarjeta-poliza .body {
  min-height: 380px;
  width: 100%;
}
.tarjeta-poliza .body .header {
  height: 70px;
}
.tarjeta-poliza .body .sub-title {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 1rem;
  text-align: center;
  color: #000000;
}
.tarjeta-poliza .body .title {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 900;
  font-size: 36px;
  line-height: 44px;
  text-align: center;
  text-transform: uppercase;
  margin: 0px;
}
.tarjeta-poliza .body .logo-header {
  margin-top: 10px;
}
.tarjeta-poliza .body hr {
  margin: 0px -10px;
}
.tarjeta-poliza .body h4 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 149.4%;
  text-align: center;
  margin: 15px 15px;
}
.tarjeta-poliza .body img {
  vertical-align: middle;
}
/*******************************************/
/*BEGIN - tarjeta recibo */
.tarjeta-recibo {
  width: calc(100% - 10px);
  min-width: 200px;
  max-width: 350px;
  position: relative;
  min-height: 160px;
  height: auto;
  margin: 10px auto;
  padding: 15px 15px;
  cursor: pointer;
  background-color: white;
  border-radius: 8px;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
    0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12);
}
.tarjeta-recibo .label-etiqueta,
.tarjeta-recibo .label-value {
  cursor: default;
}
.tarjeta-recibo:hover {
  -webkit-box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
    0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
/*******************************************/
/*BEGIN - tarjeta asegurado */
.tarjeta-asegurado {
  width: calc(100% - 10px);
  min-width: 200px;
  max-width: 350px;
  position: relative;
  min-height: 160px;
  height: auto;
  margin: 10px auto;
  padding: 5px 10px;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
    0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12);
}
.tarjeta-asegurado .label-etiqueta,
.tarjeta-asegurado .label-value,
.tarjeta-asegurado h6 {
  cursor: default;
}
.tarjeta-asegurado:hover {
  -webkit-box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
    0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
/*******************************************/
/*BEGIN - tarjeta reclamacion */
.tarjeta-reclamacion {
  width: calc(100% - 10px);
  min-width: 200px;
  max-width: 350px;
  position: relative;
  min-height: 160px;
  height: auto;
  margin: 10px auto;
  padding: 15px;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
    0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12);
}
.tarjeta-reclamacion .label-etiqueta,
.tarjeta-reclamacion .label-value,
.tarjeta-reclamacion h6 {
  cursor: default;
}
.tarjeta-reclamacion:hover {
  -webkit-box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
    0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
/*******************************************/
/*BEGIN - recibo numero */
.recibo-numero {
  font-weight: 500;
  font-size: 1.6rem;
}
/*******************************************/
/*BEGIN - Botones en base a estatus */
.btn-border-capturada,
.btn-border-pendiente,
.btn-border-proceso,
.btn-border-vigente,
.btn-border-anticipada,
.btn-border-gracia,
.btn-border-desprotegida,
.btn-border-vencida,
.btn-border-terminada,
.btn-border-renovar,
.btn-border-renovada,
.btn-border-cancelada,
.btn-border-precancelada,
.btn-border-activo,
.btn-border-baja,
.btn-border-pendiente-alta,
.btn-border-pendiente-baja,
.btn-capturada,
.btn-pendiente,
.btn-proceso,
.btn-vigente,
.btn-anticipada,
.btn-gracia,
.btn-desprotegida,
.btn-vencida,
.btn-terminada,
.btn-renovar,
.btn-renovada,
.btn-cancelada,
.btn-precancelada,
.btn-activo,
.btn-baja,
.btn-pendiente-alta,
.btn-pendiente-baja {
  min-width: 80px;
  font-weight: bold;
  border-radius: 3px;
  background-color: #fff;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 11px;
  padding: 1px 5px 0px 5px;
  max-width: 120px;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: normal;
  word-break: normal;
  vertical-align: middle;
  line-height: 1rem;
}
.btn-circle-capturada,
.btn-circle-pendiente,
.btn-circle-proceso,
.btn-circle-vigente,
.btn-circle-anticipada,
.btn-circle-gracia,
.btn-circle-desprotegida,
.btn-circle-vencida,
.btn-circle-terminada,
.btn-circle-renovar,
.btn-circle-renovada,
.btn-circle-cancelada,
.btn-circle-precancelada,
.btn-circle-activo,
.btn-circle-baja,
.btn-circle-pendiente-alta,
.btn-circle-pendiente-baja,
.btn-circle-pagado,
.btn-circle-conciliado,
.btn-circle-cancelado,
.btn-circle-vencido {
  min-width: 80px;
  font-weight: bold;
  border-radius: 10px;
  background-color: #fff;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 11px;
  padding: 1px 5px 0px 5px;
  max-width: 120px;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: normal;
  word-break: normal;
  vertical-align: middle;
  cursor: default;
  line-height: 1rem;
}
/*Capturada 0 en Colectividad*/
.btn-border-capturada,
.btn-circle-capturada {
  border: solid 1px #7f7f7f;
  color: #7f7f7f;
}
.btn-border-capturada:hover,
.btn-border-capturada.selected,
.btn-capturada {
  color: #ffffff;
  background-color: #7f7f7f;
  cursor: pointer;
}
/*Pendiente 1 (En colectividad 0)*/
.btn-border-pendiente,
.btn-circle-pendiente {
  border: solid 1px #ff9600;
  color: #ff9600;
}
.btn-border-pendiente:hover,
.btn-border-pendiente.selected,
.btn-pendiente {
  color: #ffffff;
  background-color: #ff9600;
  cursor: pointer;
}
/*Proceso 2*/
.btn-border-proceso,
.btn-circle-proceso {
  border: solid 1px #6aa4c7;
  color: #6aa4c7;
}
.btn-border-proceso:hover,
.btn-border-proceso.selected,
.btn-proceso {
  color: #ffffff;
  background-color: #6aa4c7;
  cursor: pointer;
}
/*Vigencia 3*/
.btn-border-vigente,
.btn-circle-vigente,
.btn-circle-pagado {
  border: solid 1px #5cce56;
  color: #5cce56;
}
.btn-border-vigente:hover,
.btn-border-vigente.selected,
.btn-vigente {
  color: #ffffff;
  background-color: #5cce56;
  cursor: pointer;
}
/*Ancticipada 4*/
.btn-border-anticipada,
.btn-circle-anticipada {
  border: solid 1px #9de29a;
  color: #9de29a;
}
.btn-border-anticipada:hover,
.btn-border-anticipada.selected,
.btn-anticipada {
  color: #ffffff;
  background-color: #9de29a;
  cursor: pointer;
}
/*Gracia 5*/
.btn-border-gracia,
.btn-circle-gracia {
  border: solid 1px #6475f5;
  color: #6475f5;
}
.btn-border-gracia:hover,
.btn-border-gracia.selected,
.btn-gracia {
  color: #ffffff;
  background-color: #6475f5;
  cursor: pointer;
}
/*Desprotegido 6*/
.btn-border-desprotegida,
.btn-circle-desprotegida,
.btn-circle-vencido {
  border: solid 1px #bf0000;
  color: #bf0000;
}
.btn-border-desprotegida:hover,
.btn-border-desprotegida.selected,
.btn-desprotegida {
  color: #ffffff;
  background-color: #bf0000;
  cursor: pointer;
}
/*Vencida 7*/
.btn-border-vencida,
.btn-circle-vencida {
  border: solid 1px #ffbf00;
  color: #ffbf00;
}
.btn-border-vencida:hover,
.btn-border-vencida.selected,
.btn-vencida {
  color: #ffffff;
  background-color: #ffbf00;
  cursor: pointer;
}
/*Terminada 8*/
.btn-border-terminada,
.btn-circle-terminada {
  border: solid 1px #01579b;
  color: #01579b;
}
.btn-border-terminada:hover,
.btn-border-terminada.selected,
.btn-terminada {
  color: #ffffff;
  background-color: #01579b;
  cursor: pointer;
}
/*Renovar 9*/
.btn-border-renovar,
.btn-border-renovada,
.btn-circle-renovar,
.btn-circle-renovada {
  border: solid 1px #329999;
  color: #329999;
}
.btn-border-renovar:hover,
.btn-border-renovar.selected,
.btn-renovar,
.btn-border-renovada:hover,
.btn-border-renovada.selected,
.btn-renovada {
  color: #ffffff;
  background-color: #329999;
  cursor: pointer;
}
/*Precancelada 10*/
.btn-border-precancelada,
.btn-circle-precancelada {
  border: solid 1px #676767;
  color: #676767;
}
.btn-border-precancelada:hover,
.btn-border-precancelada.selected,
.btn-precancelada {
  color: #ffffff;
  background-color: #676767;
  cursor: pointer;
}
/*Cancelada 11*/
.btn-border-cancelada,
.btn-circle-cancelada,
.btn-circle-cancelado {
  border: solid 1px #949494;
  color: #949494;
}
.btn-border-cancelada:hover,
.btn-border-cancelada.selected,
.btn-cancelada {
  color: #ffffff;
  background-color: #949494;
  cursor: pointer;
}

/*******************************************/
/*BEGIN - Datos de etiqueta y valor*/
label.etiqueta,
label.etiqueta-min {
  color: #717171;
  padding-right: 5px;
}
label.value,
label.value-min {
  padding-left: 5px;
}
label.value.bold,
label.value-min.bold {
  font-weight: 500;
}
label.etiqueta-min,
label.value-min {
  font-size: 0.875rem;
}

label.etiqueta-min {
  margin-bottom: 0px !important;
}
label.value-min {
  margin-bottom: 0px !important;
}
/*******************************************/
/*BEGIN - colores*/
.gray {
  color: #525252;
}
.gray-claro {
  color: #b7b7b7;
}
.white {
  color: white;
}
.blue-inter {
  color: #009dd0;
}
.bg-blue-inter {
  background-color: #009dd0;
}
.purple {
  color: #0f1852;
}
.blue-degrade {
  background: linear-gradient(90deg, #006fb9 0%, #1547a0 100%);
}
.black {
  color: black;
}
.red {
  color: red;
}
/*******************************************/
/*Begin - Text Elipsis*/
.text-elipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text-elipsis-one-line {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box !important;
  overflow: hidden;
}
.text-elipsis-two-line {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box !important;
  overflow: hidden;
}
.text-elipsis-three-line {
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box !important;
  overflow: hidden;
}
.text-elipsis-five-line {
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  display: -webkit-box !important;
  overflow: hidden;
}
.text-elipsis-six-line {
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  display: -webkit-box !important;
  overflow: hidden;
}
/*******************************************/
/*BEGIN - lbl etiqueta y value */
.lbl-etiqueta {
  width: 100%;
  display: block;
  color: rgba(0, 0, 0, 0.64) !important;
  line-height: 1.125;
  font: inherit;
  font-size: 8pt;
  margin-top: 10px;
}
.lbl-value {
  width: 100%;
  display: block;
  margin-bottom: 10px;
}

/*******************************************/
/*BEGIN - media min */
@media (max-width: 320px) {
  /*ssm*/
  .text-ssm-center {
    text-align: center;
  }
}
@media (max-width: 576px) {
  /*sm*/
  .text-ssm-center {
    text-align: center;
  }
}
@media (min-width: 320px) {
  /*ssm*/
  .py-sm-10x {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
}
@media (min-width: 576px) {
  /*sm*/
  .py-sm-10x {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
}
@media (min-width: 320px) {
  /*md*/
  .cdk-overlay-pane {
    max-width: 90vw !important;
  }
}
@media (min-width: 768px) {
  /*md*/
  .cdk-overlay-pane {
    max-width: 80vw !important;
  }
}
@media (min-width: 992px) {
  /*lg*/
  .cdk-overlay-pane {
    max-width: 80vw !important;
  }
}
@media (min-width: 1200px) {
  /*xl*/
  .cdk-overlay-pane {
    max-width: 80vw !important;
  }
}
@media (min-width: 1280px) {
  /*xl*/
  .cdk-overlay-pane {
    max-width: 80vw !important;
  }
}

.tarjeta-form {
  width: calc(100% - 10px);
  min-width: 300px;
  max-width: 720px;
  position: relative;
  min-height: 280px;
  height: auto;
  margin: 10px auto;
  padding: 5px 50px;
  background-color: white;
  border-radius: 8px;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
    0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12);
  padding-bottom: 35px;
}
.tarjeta-form .label-etiqueta,
.tarjeta-form .label-value {
  cursor: default;
}
.tarjeta-form:hover {
  -webkit-box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
    0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
.tarjeta-form .lbl-titulo {
  font-family: "Montserrat", Roboto, "Helvetica Neue", sans-serifs;
  font-weight: bold;
  font-size: 15pt;
  color: #000;
  margin-top: 35px;
  margin-bottom: 35px;
}
.tarjeta-form .lbl-t {
  font-family: "Montserrat", Roboto, "Helvetica Neue", sans-serifs;
  font-size: 8pt;
  color: #000;
  margin-top: -25px;
  margin-bottom: 35px;
}
input[type="text"],
input[type="password"],
input[type="number"],
input[type="email"],
input[type="date"] {
  display: block;
  width: 100%;
  max-width: 780px;
  height: calc(1.5em + 1.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.75rem;
  margin-bottom: 5px;
  font: 400 10px "Montserrat";
  font-family: "Montserrat", Roboto, "Helvetica Neue", sans-serifs;
  font-style: italic;
}
input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"],
input[type="date"]:focus,
input[type="number"]:focus {
  color: #495057;
  border-color: #80bdff;
  outline: none;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

input[type="text"]:focus,
input[type="email"],
input[type="number"]:focus,
input[type="password"]:focus {
  margin: 5px;
}

input:disabled {
  background-color: #e9ecef;
}
/*
input:invalid {
    border: 1px solid #f44336;
    outline: none;
    color:#f44336;
}
input:valid {
    border: 1px solid #999999;
}
input:required {
    border: 1px solid #999999;
    outline: none;
    color:#495057;
}
*/
textarea {
  display: block;
  width: 100%;
  max-width: 480px;
  height: auto;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.75rem;
  margin-bottom: 20px;
  font: 400 16px "Montserrat";
  font-family: "Montserrat", Roboto, "Helvetica Neue", sans-serifs;
  font-style: italic;
}
textarea:focus {
  outline: none;
  border: 1px solid #999999;
}
.button-continuar{
    width: 100%;
    height: 50px;
    color: #FFFFFF;
    background: #DD0080;
    border-radius: 10px;
}
.linked {
  cursor:pointer; cursor: hand;
}
select {
  display: block;
  width: 100%;
  max-width: 780px;
  height: calc(3.3em);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.75rem;
  margin-bottom: 5px;
  font: 400 10px "Montserrat";
  font-family: "Montserrat", Roboto, "Helvetica Neue", sans-serifs;
  font-style: italic;
}
select:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
/* Begin Loader */
.loader {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #3498db;
  width: 50px;
  height: 50px;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* End Loader */
.display-inline-block {
  display: inline-block;
}
.subtitulo-section {
  font-size: 28px;
  font-weight: 500;
  margin: 30px 0px;
  line-height: 30px;
  margin: 0px;
}
.lh-15vh {
  line-height: 15vh;
}
.profile-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
/*Begin - Text Elipsis*/
.text-elipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text-elipsis-one-line {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box !important;
  overflow: hidden;
}
.text-elipsis-two-line {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box !important;
  overflow: hidden;
}
.text-elipsis-three-line {
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box !important;
  overflow: hidden;
}
.text-elipsis-five-line {
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  display: -webkit-box !important;
  overflow: hidden;
}
.text-elipsis-six-line {
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  display: -webkit-box !important;
  overflow: hidden;
}
/*END - Text Elipsis*/
h5 {
  font-weight: 400;
  margin: 0px;
}
.lb-form {
  font-family: Montserrat;
  font-style: italic;
  font-weight: normal;
  font-size: 12px;
  color: #000000;
  margin-left: 10px;
}
.lb-form2 {
  font-family: Montserrat;
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  color: #000000;
  margin-left: 5px;
  text-align: right;
}
.btn-ayuda {
  position: fixed !important;
  z-index: 3;
  background: #009dd0;
  border-radius: 30px !important;
  height: 45px;
  width: 126px;
  bottom: 0;
  right: 0;
  margin-bottom: 10px !important;
  margin-right: 10px !important;
  border: solid 0.5px #fff !important;
  top: 500px !important;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
    0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12) !important;
}
.font-ayuda {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px !important;
  color: #ffffff;
}

.myapp-no-padding-dialog .mat-dialog-container {
  padding: 0 0 !important;
  margin: 0 0 !important;
}

.text-montserrat {
  font-family: "Montserrat", sans-serif;
}

.border-lightgray {
  border: 0.5px solid #e3e3e3;
}

@media screen and (max-width: 600px) {
  .ocultar-btn {
    display: none !important;
  }
}


input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

input[type=number] { -moz-appearance:textfield; }

h6 {
  font-size: 0.85rem !important;
}

.MuiSelect-root fieldset.MuiOutlinedInput-notchedOutline {
  border-radius: 10px;
  top: 0px;
}

.MuiSelect-root{
  min-width:150px;
  width:100%;
}

.MuiSelect-select{
  padding: 7px 12px!important;
  color: #495057;
}

.MuiFormHelperText-root.Mui-error {
  color: #d32f2f;
  font-weight: 400;
  font-size: 0.75rem;
}

.selecciona-plataforma-text {
  font-family: Montserrat;
  font-style: italic;
  font-weight: normal;
  font-size: 16px;
}

.seleccionar-opciones-text {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
}

.modelo-descripcion {
  font-family: Montserrat;
  font-style: italic;
  font-weight: normal;
  font-size: 16px;
}

.recuadro {
  font-weight: 900;
}

.form-button {
  background-color: #dd0080!important;
  color: white!important;
  border-radius: 10px!important;
  height: 50px;
  font-weight: 900!important;
}

.form-button:hover {
  background-color: #dd0080!important;
  color: white;
  border-radius: 10px!important;
}

.MuiInputBase-inputSizeSmall {
  height: 30px!important;
}

.MuiTypography-body1 {
  font-size: 1rem;
  font-family: Montserrat;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  color: black;
  text-transform: uppercase;
}

.align-center {
  align-items: center;
}