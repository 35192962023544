.logo-marca{
    background-color: #fff;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.14);
}
.shape-cotizacion{    
    padding: 0px 10px 5px 10px;
}
html {
    font-family: 'Montserrat', tahoma, verdana, arial, sans-serif !important;
    font-size: 16px;
}
.align-self-right{
    text-align: right !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
}
.align-self-border{
    border-bottom: 2px solid #C7C7C7;
}
.card-footer-left{
    float: left;
    cursor: default;    
    vertical-align: middle;
}
.card-footer-right{
    float: right;
    display: inline-table;
    vertical-align: middle
}
.mini-icon{
    width: 35px;
    height: 20px;
    margin: 0px 3px;
}
.button-cotiza{
    width: 190px;
    height: 40px;
    background: #009DD0;
    border-radius: 10px;
}
.button-contrata{
    width: 100%;
    height: 100%;
    background: #DD0080;
    border-radius: 10px;
}
.header-section{
    width: 100%;
    height: auto;
    background-color:rgb(14, 23, 81);
}
.header-section .container{
    top: 30px;
    left: 0px;
    right: 0px;
}
.header-paquete{
    width: 100%;
    min-height: 60px;
    background-color: #009DCE;
}
.header-paquete .container, .header-paquete .container .row{
    min-height: 60px;
}
.header-paquete .container .row .col-12 .cbFormaPago,.header-paquete .container .row .col-12 .cbPaquete{
    display: inline;
    margin: auto;
}
.header-picker{
    width: 100%;
    min-height: 60px;
    background-color: #88DFF9;
}
.header-picker .container, .header-picker .container .row{
    min-height: 60px;
}
.header-picker .container .row p{
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
}
.contenedor-vigencia{ position: relative; }
.icoInfo{ color: #2F80ED; cursor: pointer; }
.dialogo, .paquete-dialogo {
    width: 100vw;
    height: auto;
    color: #000;
    background-color: #283773;
    max-width: 300px;
    max-height: 180px;
    padding: 5px 10px;
    position: absolute;
    z-index: 2;
    display: none;
    color: white;
    border-radius: 10px;
}
.paquete-dialogo { left: 0px; margin-top: -85px; }

@media (max-width: 320px) { /*ssm*/
    .dialogo{ right: 20px; margin-top: -160px; }   
}
@media (max-width: 576px) { /*sm*/
    .dialogo{ right: 20px; margin-top: -160px; }   
}
@media (min-width: 320px) { /*sm*/
    .align-self-cot{
        align-self: center;
    }
    #guardaCot1{  
        display: block !important;
    }
    #guardaCot2{  
        display: none !important;
    }
}
@media (min-width: 576px) { /*sm*/
    .align-self-cot{
        align-self: center;
    }
    #guardaCot1{  
        display: block !important;
    }
    #guardaCot2{  
        display: none !important;
    }
}
@media (min-width: 768px) { /*md*/
    .dialogo{ right: 20px; margin-top: -160px; }   
    .align-self-cot{
        align-self: center;
    }
    #guardaCot1{  
        display: none !important;
    }
    #guardaCot2{  
        display: block !important;
    }
}
@media (min-width: 992px) { /*lg*/
    .dialogo{ right: -50px; margin-top: -170px; }   
    .align-self-cot{
        align-self: center;
    }
    #guardaCot1{  
        display: none !important;
    }
    #guardaCot2{  
        display: block !important;
    }
}
@media (min-width: 1200px) { /*xl*/
    .dialogo{ right: -50px; margin-top: -170px; }   
    .border-left-1x{
        border-left: 1px solid white;
    }
    .align-self-cot{
        align-self: flex-end;
    }
    #guardaCot1{  
        display: block !important;
    }
    #guardaCot2{  
        display: none !important;
    }
}
.icoInfo:hover~.dialogo, .icoInfo:hover~.paquete-dialogo {
    display: block;
}
.fecVigencia{
    max-width: 200px;
    margin-bottom: 0px;
}
.contenedor-vigencia p.mensaje{
    font-size: 14px !important;
    line-height: 16px !important;
}
.minh-100x{
    min-height: 100px;
}
.contenedor{
    max-width: 550px;
    width: 100%;
    float: left;
}
.container .lbl-title{
    color: white;
    display: inline;
    max-width: 190px;
    font-size: 12px;
}
.container .lbl-value{
    color: white;
    display: inline;
    max-width: 400px;
    font-weight: 600;
    font-size: 12px;
    line-height: 1rem;
}
.container .lbl-value.line{
    display: block;
    max-width: 380px;
    border-bottom: solid 1px white;
}
@media (max-width: 768px) { /*sm*/
    .mat-flat-button.mat-inter, .mat-stroked-button.mat-inter{
        font-size: 12px;
        min-width: 130px;
        margin: 5px 10px;
    }
}
@media (max-width: 340px) { /*sm*/
    .mat-flat-button.mat-inter, .mat-stroked-button.mat-inter{
        font-size: 12px;
        min-width: 100px;
        margin: 5px;
    }
}
.btn-back {
    color: #039ECC;
    font-size: 16px;
    font-family: "Montserrat", Roboto,"Helvetica Neue",sans-serif;
    cursor: pointer;
    margin: 10px 0px;
}
.h-135x{
    height: 135px;
}
.logoCoche{
    margin-top: 20px;
    margin-bottom: 20px;
    height: 70px;
}
.tarjeta-poliza .body .sub-title.desc{
    min-height: 48px;
}
.fs-9pt{
    font-size: 9pt;
}
.input-picker{
    width: 200px;
    max-width: 480px;
    height: calc(1.5em + 1.25rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .75rem;
    margin-bottom: 5px;
    font: 400 16px 'Montserrat';
    font-family: 'Montserrat', Roboto, "Helvetica Neue", sans-serifs;
    font-style: italic;
    position: relative;
}
.input-picker .table-sio .row-sio .cell-sio{
    vertical-align: middle;
}
.input-picker .table-sio{
    height: 35px;
}
.input-picker div.etiqueta{
    width: 150px;
    margin-top: 7px;
}
.input-picker input{
    width: 0px;
    vertical-align: middle;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    border: none;
    background-color: #fff;
    font: 400 16px 'Montserrat';
    font-family: 'Montserrat', Roboto, "Helvetica Neue", sans-serifs;
    font-style: italic;
    height: 0px;
}
.input-picker input:focus{
    color: #495057;
    border-color: none;
    outline: none;
    outline: 0;
}
.input-picker .icon-calendar{
    cursor: pointer;
    color: #DD0080;
    vertical-align: middle;
    margin-top: -17px;
}
.container-fluid .lbl-title{
    color: white;
    display: inline;
    max-width: 190px;
    font-size: 12px;
}
.container-fluid .lbl-value{
    color: white;
    display: inline;
    max-width: 400px;
    font-weight: 600;
    font-size: 12px;
    line-height: 1rem;
}
.container-fluid .lbl-value.line{
    display: block;
    max-width: 380px;
    border-bottom: solid 1px white;
}
.justify-content-end{
    justify-content: flex-end;
}
.color-icon-green {
    color: green;
}
.color-icon-red {
    color: red;
}
.font-conbertura{
    font-size: 26px;
    font-weight: 600;
    font-family: Montserrat;
    font-style: normal;
}
.font-12{
    font-size: 12px !important;
}

.border-row{
    border-bottom: 1px solid #CDCDCD;
}

.table-containeruber{
    border-top: 8px solid #222222;
    border-radius: 5px;
    background-color: white;
    font-family: Montserrat !important;
    border-left: 1px solid #000000;
    border-bottom: 1px solid #000000;
    border-right: 1px solid #000000;
    height: 1200px;
}
.table-containerapp{
    border-top: 8px solid #996E1B;
    border-radius: 5px;
    background-color: white;
    font-family: Montserrat !important;
    border-left: 1px solid #000000;
    border-bottom: 1px solid #000000;
    border-right: 1px solid #000000;
    height: 1200px;
}
.table-containerdetalle{
    border-top: 8px solid #000000;
    border-radius: 5px;
    background-color: white;
    font-family: Montserrat !important;
    border-left: 1px solid #000000;
    border-bottom: 1px solid #000000;
    border-right: 1px solid #000000;
}
.app24 {
    font-size: 24px;
    font-family: Montserrat !important;
}
.app12 {
    font-size: 12px;
    font-family: Montserrat !important;
}
.app36 {
    font-size: 36px;
    font-family: Montserrat !important;
    font-weight: bold;
}
.app72 {
    font-size: 72px;
    font-family: Montserrat !important;
    color: #FFFFFF;
    font-weight: bold;
}
.app16 {
    font-size: 16px;
    font-family: Montserrat !important;
}
.app14 {
    font-size: 14px;
    font-family: Montserrat !important;
}
.app18 {
    font-size: 18px;
    font-family: Montserrat !important;
}
.appcolor {
   color: #DD0080;
   font-family: Montserrat !important;
}
.appcolorTable {
    color: #000000;
    font-family: Montserrat !important;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
}
.fs-11{
 font-size: 11px;
}
::ng-deep .tool-tip {
    color: #000;
    background-color: #283773;
    font-family: Montserrat;
    font-size: 12px !important;
}
.mh-220x{
    min-height: 220px;
}
.h-25x{
    height: 25px;
}
.w-130x{
    width: 130px;
}

@media screen and (max-width: 600px) {
    .ocultar-btn{
      display:none;
    }
}
.lb-conf-cot{
    font-family: Montserrat;
    font-size: 12px;
    color: #ffffff;
    margin-left: 10px;
    font-weight: 600;
    display: block;
}
@media (min-width: 300px) and (max-width: 575px) {
    .table-containeruber{
    border-top: 8px solid #222222;
    border-radius: 5px;
    background-color: white;
    font-family: Montserrat !important;
    border-left: 1px solid #000000;
    border-bottom: 1px solid #000000;
    border-right: 1px solid #000000;
    height: 100%;
}
.table-containerapp{
    border-top: 8px solid #996E1B;
    border-radius: 5px;
    background-color: white;
    font-family: Montserrat !important;
    border-left: 1px solid #000000;
    border-bottom: 1px solid #000000;
    border-right: 1px solid #000000;
    height: 100%;
}
    .w-25x-movil{
        width: 25px !important;
    }
    .w-100-movil{
        width: 100% !important;
    }
}

@media (min-width: 768px) and (max-width: 1199px) {
    .p-tablet-60x{
        padding-left: 60px !important;
    }
}

@media (max-width: 320px) {
    /*ssm*/
    .table-containeruber,
    .table-containerapp {
        font-size: 0.7rem;
    }
  }
@media (max-width: 576px) {
    /*sm*/
    .table-containeruber,
    .table-containerapp {
        font-size: 0.7rem;
    }
}
@media (max-width: 768px) {
    /*med*/
    .table-containeruber,
    .table-containerapp {
        font-size: 0.7rem;
    }
  }