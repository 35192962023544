.body-estruct{
    min-height: calc(100vh - 630px);
}
.title-section{
    color: #039ECC;
    font-weight: bold;
    font-size: 32px;
    margin: 10px 0px;
}
.icoInfo { color: #2F80ED; cursor: pointer; margin-right: 10px; }
.icoInfo:hover {
    display: block;
}
::ng-deep .tool-tip {
    color: #000;
    background-color: #283773;
    font-family: Montserrat;
    font-size: 12px !important;
    border-radius: 10px;
}
.icon-section{
    background-color: #039ECC;
    color: white;
    padding: 5px;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    text-align: center;
    font-size: 18px;
    margin-left: 30px;
    cursor: pointer;
}
.lbl-etiqueta {
    width: 100%;
    display: block;
    color: rgba(0,0,0,.64)!important;
    line-height: 1.125;
    font: inherit;
    font-size: 8pt;
    font-family: Roboto,"Helvetica Neue",sans-serif;
    margin-top: 10px;
}
.lbl-value {
    width: 100%;
    display: block;
    margin-bottom: 10px;
}
.btn-back {
    color: #039ECC;
    font-size: 16px;
    font-family: "Montserrat", Roboto,"Helvetica Neue",sans-serif;
    cursor: pointer;
    margin: 10px 0px;
}
.lbl-separate {
    color: #818181;
    float: left;
    font-size: 18pt;
    font-family: "Segoe UI";
    margin: 0px 10px 0px 10px;
}
.lbl-paso {
    color: #818181;
    float: left;
    font-size: 14px;;
}
.icon-paso-num {
    background-color: #535150;
    color: white;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    text-align: center;
    margin-right: 10px;
    float: left;
    font-size: 14px;
}
.lbl-separate-disabled {
    color: #BBBBBB;
    float: left;
    font-family: "Segoe UI";
    margin: 0px 10px 0px 10px;
}
.lbl-paso-disabled {
    color: #BBBBBB;
    float: left;
    font-size: 14px;;
}
.icon-paso-num-disabled {
    background-color: #BBBBBB;
    color: white;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    text-align: center;
    margin-right: 10px;
    float: left;
    font-size: 14px;
}
.lbl-subtitle {
    color: #222222;
    font-size: 16px;
    font-family: "Montserrat", Roboto,"Helvetica Neue",sans-serif;
    text-align: center;
    margin: 25px 0 25px 0;
}
.lb-correo{
    font-family: Montserrat;
    font-style: italic;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    margin-bottom: 20px;
    margin-top: 10px;
}
.bold{
    font-weight: 600;
}

@media (min-width: 320px) { /*ssm*/
    .w-ssm-100{
        width: 100%;
    } 
}

@media (min-width: 576px) { /*sm*/
    .w-sm-100{
        width: 100%;
    } 
}
@media (min-width: 768px) { /*md*/
    .w-md-50{
        width: 50%;
    }
    .w-md-45{
        width: 45%;
    }
}
@media (min-width: 992px) { /*lg*/
}
@media (min-width: 1200px) { /*xl*/
}
@media (min-width: 1280px) { /*xl*/
}
@media screen and (max-width: 600px) {
    .ocultar-btn{
      display:none;
    }
}